@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

.contact {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 12vh;
  background-color: #f5f5f4;
  font-family: 'Inter', sans-serif;
}

.contact-content {
  max-width: 80vw;
  width: 100%;
  margin: 0 auto;
}

.contact-content h2 {
  font-size: 4vh;
  color: #0f172a;
  margin-bottom: 3vh;
  text-align: center;
  font-weight: 700;
  letter-spacing: -0.5px;
}

.contact-intro {
  text-align: center;
  font-size: 2.2vh;
  color: #334155;
  margin-bottom: 6vh;
  max-width: 60vw;
  margin-left: auto;
  margin-right: auto;
}

.contact-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3vh;
  max-width: 60vw;
  margin: 0 auto;
}

.contact-item {
  background: white;
  border-radius: 2vh;
  padding: 3vh;
  display: flex;
  align-items: center;
  gap: 2vh;
  box-shadow: 0 0.4vh 1.2vh rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  position: relative;
}

.contact-item:hover {
  transform: translateY(-0.5vh);
  background-color: #f8fafc;
}

.contact-icon {
  width: 5vh;
  height: 5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2563eb;
  border-radius: 1vh;
  color: white;
  font-size: 2.5vh;
}

.contact-info h3 {
  font-size: 2vh;
  color: #0f172a;
  margin-bottom: 0.5vh;
  font-weight: 600;
}

.contact-info p {
  font-size: 1.8vh;
  color: #64748b;
}

.copy-feedback {
  position: absolute;
  top: 1vh;
  right: 1vh;
  background-color: #2563eb;
  color: white;
  padding: 0.5vh 1vh;
  border-radius: 0.5vh;
  font-size: 1.4vh;
  animation: fadeIn 0.3s ease;
}

.footer {
  width: 100%;
  padding: 4vh 0;
  margin-top: 8vh;
  background-color: rgba(15, 23, 42, 0.02);
  border-top: 1px solid #e5e7eb;
}

.copyright {
  text-align: center;
  color: #64748b;
  font-size: 1.6vh;
}

.copyright p {
  margin: 0.5vh 0;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@media (max-width: 768px) {
  .contact-grid {
    grid-template-columns: 1fr;
    max-width: 90vw;
  }

  .contact-intro {
    max-width: 90vw;
    font-size: 2vh;
  }

  .contact-item {
    padding: 2vh;
  }

  .contact-icon {
    width: 4vh;
    height: 4vh;
    font-size: 2vh;
  }

  .contact-info h3 {
    font-size: 1.8vh;
  }

  .contact-info p {
    font-size: 1.6vh;
  }
} 