@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

.experience {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f4;
  font-family: 'Inter', sans-serif;
  border-bottom: 1px solid #e5e7eb;
}

.experience-content {
  max-width: 90vw;
  width: 100%;
}

.experience-content h2 {
  font-size: 4vh;
  color: #0f172a;
  margin-bottom: 5vh;
  text-align: center;
  font-weight: 700;
  letter-spacing: -0.5px;
}

.experience-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3vw;
  max-width: 80vw;
  margin: 0 auto;
}

.experience-card {
  background: white;
  border-radius: 2vh;
  height: 65vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0.4vh 1.2vh rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.experience-card:hover {
  transform: translateY(-0.5vh);
}

.experience-header {
  padding: 3vh;
  border-bottom: 1px solid #e5e7eb;
  background: rgba(15, 23, 42, 0.02);
  display: flex;
  align-items: center;
  gap: 2vh;
}

.company-logo {
  width: 6vh;
  height: 6vh;
  flex-shrink: 0;
}

.company-logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.header-content {
  flex-grow: 1;
}

.experience-header h3 {
  font-size: 2.4vh;
  color: #0f172a;
  margin-bottom: 0.5vh;
  font-weight: 600;
}

.experience-header h4 {
  font-size: 2vh;
  color: #2563eb;
  margin-bottom: 0.5vh;
  font-weight: 500;
}

.meta-info {
  display: flex;
  gap: 2vh;
  font-size: 1.6vh;
  color: #64748b;
}

.achievements-list {
  list-style-type: none;
  padding: 3vh;
  margin: 0;
  overflow-y: auto;
}

.achievements-list li {
  font-size: 1.8vh;
  color: #334155;
  margin-bottom: 2vh;
  padding-left: 2.5vh;
  position: relative;
  line-height: 1.6;
}

.achievements-list li::before {
  content: "•";
  position: absolute;
  left: 0.5vh;
  color: #2563eb;
  font-weight: bold;
}

@media (max-width: 1200px) {
  .experience-grid {
    grid-template-columns: 1fr;
    max-width: 90vw;
  }

  .experience-card {
    height: auto;
    min-height: 50vh;
  }
}

@media (max-width: 768px) {
  .experience-header {
    padding: 2vh;
  }

  .achievements-list {
    padding: 2vh;
  }

  .achievements-list li {
    font-size: 1.6vh;
    margin-bottom: 1.5vh;
  }
} 