@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

.skills {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f4;
  font-family: 'Inter', sans-serif;
  border-bottom: 1px solid #e5e7eb;
}

.skills-content {
  max-width: 90vw;
  width: 100%;
}

.skills-content h2 {
  font-size: 4vh;
  color: #0f172a;
  margin-bottom: 5vh;
  text-align: center;
  font-weight: 700;
  letter-spacing: -0.5px;
}

.skills-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3vh 2vw;
}

.skill-category {
  background: white;
  border-radius: 2vh;
  overflow: hidden;
  box-shadow: 0 0.4vh 0.6vh -0.1vh rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.skill-category:hover {
  transform: translateY(-0.5vh);
}

.skill-header {
  background-color: rgba(15, 23, 42, 0.95);
  padding: 2vh;
  text-align: center;
}

.skill-header h3 {
  color: white;
  font-size: 2.2vh;
  font-weight: 600;
  margin: 0;
}

.skill-items {
  display: flex;
  flex-wrap: wrap;
  gap: 1vh;
  padding: 2vh;
  min-height: 20vh;
  align-content: flex-start;
}

.skill-item {
  background-color: #f1f5f9;
  color: #334155;
  padding: 0.8vh 1.2vw;
  border-radius: 0.8vh;
  font-size: 1.6vh;
  font-weight: 500;
  transition: all 0.3s ease;
}

.skill-item:hover {
  background-color: #2563eb;
  color: white;
  transform: translateY(-0.2vh);
}

@media (max-width: 1200px) {
  .skills-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .skills-grid {
    grid-template-columns: 1fr;
  }

  .skill-items {
    min-height: auto;
  }
} 