@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 7%;
  background-color: rgba(15, 23, 42, 0.95);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  z-index: 1000;
  transition: all 0.3s ease;
  font-family: 'Inter', sans-serif;
}

.nav-brand {
  font-size: 1.5rem;
  font-weight: 700;
  color: #f8fafc;
  text-decoration: none;
  letter-spacing: -0.5px;
}

.nav-links {
  display: flex;
  gap: 3rem;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-links li a {
  color: #cbd5e1;
  text-decoration: none;
  font-size: 1.1rem;
  font-weight: 500;
  transition: all 0.2s ease;
  padding: 0.5rem 0;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  background: none;
}

.nav-links li a:hover {
  color: #60a5fa;
}

.nav-links li a::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #60a5fa;
  transition: width 0.2s ease;
  border-radius: 1px;
}

.nav-links li a:hover::after {
  width: 100%;
}

.nav-toggle {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
}

.hamburger,
.hamburger::before,
.hamburger::after {
  display: block;
  position: relative;
  width: 24px;
  height: 2px;
  background: #f8fafc;
  transition: all 0.3s ease;
}

.hamburger::before,
.hamburger::after {
  content: '';
  position: absolute;
}

.hamburger::before {
  top: -6px;
}

.hamburger::after {
  bottom: -6px;
}

/* Mobile Responsive Styles */
@media (max-width: 768px) {
  .nav-toggle {
    display: block;
  }

  .nav-links {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    flex-direction: column;
    align-items: center;
    gap: 0;
    background-color: rgba(15, 23, 42, 0.85);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    padding: 1.5rem 0;
    transform: translateY(-150%);
    opacity: 0;
    transition: all 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .nav-links.active {
    transform: translateY(0);
    opacity: 1;
  }

  .nav-links li {
    width: 100%;
    text-align: center;
  }

  .nav-links li a {
    display: block;
    padding: 1.2rem 0;
  }
}

/* Scrolled state */
.navbar.scrolled {
  background-color: rgba(15, 23, 42, 0.85);
  padding: 1.2rem 7%;
} 