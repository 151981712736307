@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

.education {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10vh;
  background-color: #f5f5f4;
  font-family: 'Inter', sans-serif;
  border-bottom: 1px solid #e5e7eb;
}

.education-content {
  max-width: 80vw;
  width: 100%;
  margin: 0 auto;
}

.education-content h2 {
  font-size: 4vh;
  color: #0f172a;
  margin-bottom: 5vh;
  text-align: center;
  font-weight: 700;
  letter-spacing: -0.5px;
}

.education-card {
  background: white;
  border-radius: 2vh;
  overflow: hidden;
  box-shadow: 0 0.4vh 1.2vh rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.education-card:hover {
  transform: translateY(-0.5vh);
}

.education-header {
  padding: 3vh;
  border-bottom: 1px solid #e5e7eb;
  background: rgba(15, 23, 42, 0.02);
  display: flex;
  align-items: center;
  gap: 2vh;
}

.institution-logo {
  width: 8vh;
  height: 8vh;
  flex-shrink: 0;
}

.institution-logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.header-content h3 {
  font-size: 2.4vh;
  color: #0f172a;
  margin-bottom: 0.5vh;
  font-weight: 600;
}

.header-content h4 {
  font-size: 2vh;
  color: #2563eb;
  font-weight: 500;
}

.education-details {
  padding: 3vh;
}

.detail-item {
  display: flex;
  gap: 1.5vh;
  margin-bottom: 2vh;
}

.detail-bullet {
  color: #2563eb;
  font-weight: bold;
}

.detail-item p {
  font-size: 1.8vh;
  color: #334155;
  line-height: 1.6;
  margin: 0;
}

.certifications {
  margin-top: 4vh;
  padding-top: 3vh;
  border-top: 1px solid #e5e7eb;
}

.certifications h4 {
  font-size: 2.2vh;
  color: #0f172a;
  margin-bottom: 2vh;
  font-weight: 600;
}

.certification-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2vh;
}

.certification-item {
  background: #f8fafc;
  padding: 2vh;
  border-radius: 1vh;
  display: flex;
  align-items: center;
  gap: 1vh;
  transition: all 0.3s ease;
}

.certification-item:hover {
  background: #2563eb;
  color: white;
  transform: translateY(-0.2vh);
}

.certification-item:hover .cert-text {
  color: white;
}

.cert-icon {
  font-size: 2.2vh;
}

.cert-text {
  font-size: 1.6vh;
  color: #334155;
  font-weight: 500;
  transition: color 0.3s ease;
}

@media (max-width: 768px) {
  .education-content {
    max-width: 90vw;
  }

  .certification-grid {
    grid-template-columns: 1fr;
  }

  .detail-item p {
    font-size: 1.6vh;
  }

  .certification-item {
    padding: 1.5vh;
  }
} 