@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

.hero {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f4;
  font-family: 'Inter', sans-serif;
  border-bottom: 1px solid #e5e7eb;
}

.hero-content {
  max-width: 90vw;
  width: 100%;
  text-align: center;
  margin-top: 5vh;
}

.hero-image-container {
  margin-bottom: 3vh;
}

.hero-image {
  width: 45vh;
  height: 45vh;
  border-radius: 50%;
  object-fit: cover;
  border: 0.4vh solid #60a5fa;
  box-shadow: 0 0.4vh 0.6vh -0.1vh rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.hero-image:hover {
  transform: scale(1.05);
}

.hero-text h1 {
  font-size: 5vh;
  font-weight: 700;
  color: #0f172a;
  margin-bottom: 2vh;
  letter-spacing: -0.5px;
  font-family: 'Inter', sans-serif;
}

.hero-text h2 {
  font-size: 3vh;
  font-weight: 500;
  color: #64748b;
  margin-bottom: 4vh;
  font-family: 'Inter', sans-serif;
}

.cta-buttons {
  display: flex;
  gap: 2vw;
  justify-content: center;
}

.cta-buttons button {
  padding: 1.5vh 3vw;
  font-size: 2vh;
  font-weight: 500;
  border-radius: 1vh;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: 'Inter', sans-serif;
}

.cta-primary {
  background-color: #2563eb;
  color: white;
  border: none;
}

.cta-primary:hover {
  background-color: #1d4ed8;
  transform: translateY(-2px);
}

.cta-secondary {
  background-color: transparent;
  color: #2563eb;
  border: 2px solid #2563eb;
}

.cta-secondary:hover {
  background-color: #2563eb;
  color: white;
  transform: translateY(-2px);
}

@media (max-width: 768px) {
  .hero-text h1 {
    font-size: 4vh;
  }

  .hero-text h2 {
    font-size: 2.5vh;
  }

  .cta-buttons {
    flex-direction: column;
    gap: 2vh;
  }

  .cta-buttons button {
    width: 100%;
  }
} 