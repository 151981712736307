@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

.projects {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 12vh;
  background-color: #f5f5f4;
  font-family: 'Inter', sans-serif;
  border-bottom: 1px solid #e5e7eb;
}

.projects-content {
  max-width: 70vw;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 5vh;
}

.projects-content h2 {
  font-size: 4vh;
  color: #0f172a;
  margin-bottom: 5vh;
  padding-bottom: 5vh;
  text-align: center;
  font-weight: 700;
  letter-spacing: -0.5px;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3vw;
  align-items: stretch;
}

.project-card {
  background: white;
  border-radius: 2vh;
  overflow: hidden;
  box-shadow: 0 0.4vh 0.6vh -0.1vh rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.project-card:hover {
  transform: translateY(-0.5vh);
}

.project-image {
  width: 100%;
  height: 180px;
  overflow: hidden;
}

.project-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.project-card:hover .project-image img {
  transform: scale(1.05);
}

.project-content {
  padding: 2vh 2vw;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.project-content h3 {
  font-size: 2.5vh;
  color: #0f172a;
  margin-bottom: 1vh;
  font-weight: 600;
}

.project-content p {
  font-size: 1.8vh;
  color: #334155;
  margin-bottom: 2vh;
  flex-grow: 1;
}

.technologies {
  display: flex;
  flex-wrap: wrap;
  gap: 1vh;
  margin-bottom: 2vh;
}

.tech-tag {
  background-color: #f1f5f9;
  color: #0f172a;
  padding: 0.5vh 1vw;
  border-radius: 1vh;
  font-size: 1.6vh;
  font-weight: 500;
  transition: all 0.3s ease;
}

.tech-tag:hover {
  background-color: #2563eb;
  color: white;
  transform: translateY(-0.2vh);
}

.project-links {
  display: flex;
  gap: 2vw;
  justify-content: flex-start;
}

.project-link {
  color: #2563eb;
  text-decoration: none;
  font-size: 1.8vh;
  font-weight: 500;
  transition: color 0.3s ease;
}

.project-link:hover {
  color: #1d4ed8;
}

@media (max-width: 1024px) {
  .projects-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .projects-grid {
    grid-template-columns: 1fr;
  }
  
  .project-image {
    height: 220px;
  }
  
  .projects-content {
    max-width: 90vw;
  }
} 